<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index: 999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card class="pa-4">
      <v-form ref="formLead">
        <v-toolbar height="40" flat class="pt-2 mb-6">
          <v-icon left>mdi-calendar-multiple-check</v-icon>
          EVENTO {{ datosEvento.work_effort_id }}
          <v-btn @click="guardarEvento" color="blue" small dark v-if="1 == 2"
            ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
          >
          <v-divider class="mx-6" vertical flat></v-divider>
          <v-btn
            small
            dark
            color="blue"
            v-if="
              !['CAL_TAR_COMPLETADO', 'CAL_TAR_CANCELADO'].includes(
                datosEvento.current_status_id
              )
            "
            @click="abrirDialogCambioEstado()"
          >
            <v-icon>mdi-toggle-switch</v-icon>cambiar estado
          </v-btn>

          <v-btn
            class="mx-4"
            small
            dark
            color="blue"
            @click="abrirDialogEnviarMensaje()"
            ><v-icon>mdi-whatsapp</v-icon>enviar mensaje</v-btn
          >
        </v-toolbar>

        <v-row class="mt-1 mx-0">
          <!-- <v-col cols="4" class="py-0 my-0">
            <v-text-field
              v-model="datosEvento.work_effort_id"
              label="Codigo"
            ></v-text-field>
          </v-col> -->
          <v-col cols="4" class="py-0 my-0">
            <v-text-field
              readonly
              v-model="datosEvento.estado"
              label="Estado actual"
            ></v-text-field>
          </v-col>

          <v-col cols="2" class="py-0 my-0">
            <v-text-field
              readonly
              v-model="duracionEvento"
              label="Duración (min)"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="py-0 my-0">
            <v-text-field
              readonly
              v-model="formatoFechaInicio"
              label="Fecha de inicio"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="py-0 my-0">
            <v-text-field
              readonly
              v-model="formatoFechaFin"
              label="Fecha de finalización"
            ></v-text-field>
          </v-col>

          <v-col cols="6" class="py-0 my-0">
            <v-text-field readonly v-model="getDatosContacto" label="Contacto">
              <template slot="prepend">
                <v-btn
                  small
                  icon
                  target="_blank"
                  title="ver contacto"
                  :to="`/contacto_form/${datosEvento.contacto_id}`"
                >
                  <v-icon>mdi-account-details</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>

          <v-col md="3" class="py-0 my-0">
            <v-text-field
              readonly
              v-model="datosEvento.created_by_user_login"
              label="Creado por"
            ></v-text-field>
          </v-col>

          <v-col md="3" class="py-0 my-0">
            <v-text-field
              readonly
              v-model="formatFechaCreacion"
              label="Fecha de creación"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-1 mx-2">
          <v-col cols="6" class="py-0 my-0">
            <v-text-field
              readonly
              v-model="datosEvento.propietario"
              label="Quien atiende"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-1 mx-2">
          <v-col md="6" class="py-0 my-0">
            <v-textarea
              rows="3"
              v-model="datosEvento.otro_motivo"
              label="Comentario"
            ></v-textarea>
          </v-col>
          <v-col md="6" class="py-0 my-0">
            <v-textarea
              rows="3"
              v-model="datosEvento.description"
              label="Descripción"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mx-0" cols="6">
            <v-toolbar color="green" dark class="mt-4" height="25" flat>
              <v-toolbar-title>Historial de comentarios</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :items-per-page="itemsPerPageCom"
              class="elevation-1"
              small
              hide-default-footer
              :items="comentariosEvento"
              :headers="headersComentarios"
            >
              <template v-slot:item.status_datetime="{ item }">
                {{
                  item.status_datetime.split(" ")[0] +
                  " || " +
                  item.status_datetime.split(" ")[1].substring(0, 5)
                }}
              </template>
            </v-data-table>
          </v-col>

          <v-col class="mx-0" cols="6">
            <v-toolbar color="green" dark class="mt-4" height="25" flat>
              <v-toolbar-title>Historial de Estados</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :items-per-page="itemsPerPageEv"
              class="elevation-1"
              small
              hide-default-footer
              :items="estadosEvento"
              :headers="headersEstados"
            >
              <template v-slot:item.status_datetime="{ item }">
                {{
                  item.status_datetime.split(" ")[0] +
                  " || " +
                  item.status_datetime.split(" ")[1].substring(0, 5)
                }}
              </template>
            </v-data-table>
          </v-col>
          <v-col class="mx-0" cols="6">
            <v-toolbar color="green" dark class="mt-4" height="25" flat>
              <v-toolbar-title>Servicios y tiempos</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :items-per-page="itemsPerPageServ"
              class="elevation-1"
              small
              hide-default-footer
              :items="subservicios"
              :headers="headersSubservicios"
            >
            </v-data-table>
          </v-col>

          <v-col class="mx-0" cols="6">
            <v-toolbar color="green" dark class="mt-4" height="25" flat>
              <v-toolbar-title>Relacionado con</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :items-per-page="itemsPerPageOp"
              class="elevation-1"
              small
              hide-default-footer
              :items="oportunidadesEvento"
              :headers="headersOportunidad"
            >
              <template v-slot:item.sales_opportunity_id="{ item }">
                <v-btn
                  v-if="item.sales_opportunity_id != null"
                  text
                  small
                  target="_blank"
                  title="abrir oportunidad"
                  :to="`/oportunidad_form/${item.sales_opportunity_id}`"
                  ><v-icon small left color="green">mdi-handshake</v-icon>
                  {{ item.sales_opportunity_id }}
                </v-btn>
              </template>
              <template v-slot:item.status_datetime="{ item }">
                {{
                  item.status_datetime.split(" ")[0] +
                  " || " +
                  item.status_datetime.split(" ")[1].substring(0, 5)
                }}
              </template>
            </v-data-table>
          </v-col>

          <v-col class="mx-0" cols="6">
            <v-toolbar color="green" dark class="mt-4" height="25" flat>
              <v-toolbar-title>Participantes</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :items-per-page="itemsPerPage"
              class="elevation-1"
              small
              hide-default-footer
              :items="participantesEvento"
              :headers="headersParticipante"
            >
              <template v-slot:item.sales_opportunity_id="{ item }">
                <v-btn
                  text
                  small
                  title="abrir oportunidad"
                  :to="`/oportunidad_form/${item.sales_opportunity_id}`"
                  ><v-icon small left color="green">mdi-eye</v-icon>
                  {{ item.sales_opportunity_id }}
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!-- DIALOGO CAMBIO DE ESTADO -->
    <v-dialog
      width="750px"
      v-model="dialogEstadoEvento"
      :persistent="true"
      no-click-animation
    >
      <v-card>
        <v-toolbar flat style="position: static" height="40" class="blue" dark>
          <v-app-bar-nav-icon>
            <template v-slot:default>
              <v-icon>mdi-36px mdi-repeat</v-icon>
            </template>
          </v-app-bar-nav-icon>
          <v-card-title class="justify-center py-0">
            Cambio de estado del evento
          </v-card-title>
        </v-toolbar>
        <v-form ref="form_estatus_evento">
          <v-card-text class="pa-4">
            <v-row class="pt-4">
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  readonly
                  v-model="datosEvento.servicio"
                  label="Servicio"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  readonly
                  v-model="datosEvento.estado"
                  label="Estado actual"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 my-0" cols="6">
                <v-text-field
                  class="my-0 py-0"
                  readonly
                  v-model="getDatosContacto"
                  label="Contacto"
                ></v-text-field>
              </v-col>
              <v-col class="my-0 py-0" cols="6">
                <v-text-field
                  class="my-0 py-0"
                  readonly
                  v-model="datosEvento.propietario"
                  label="Quien atiende"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  readonly
                  v-model="formatoFechaInicio"
                  label="Hora de inicio de la atención"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  class="my-0 py-0"
                  readonly
                  v-model="formatoFechaFin"
                  label="Hora de fin de la atención"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-autocomplete
              label="Actualizar estado"
              v-model="nuevoEstadoId"
              :items="
                estados.filter((e) => {
                  return getListaEstadosValidos().includes(e.status_id);
                })
              "
              dense
              :rules="required"
              outlined
              item-value="status_id"
              item-text="description"
            />
            <v-autocomplete
              :rules="
                getListaMotivosEstado(nuevoEstadoId).length == 0
                  ? []
                  : requiredRule
              "
              label="Motivos de cambio de estado"
              v-model="nuevoMotivoId"
              :items="getListaMotivosEstado(nuevoEstadoId)"
              dense
              outlined
              item-value="wes_motivo_id"
              item-text="motivo_nombre"
            />
            <v-textarea
              v-model="motivo"
              dense
              :rules="
                ['CAL_TAR_COMPLETADO', 'CAL_TAR_CONFIRMADO'].includes(
                  nuevoEstadoId
                ) && nuevoEstadoId != null
                  ? []
                  : requiredRule
              "
              outlined
              label="Comentario del cambio de estado"
            />
          </v-card-text>
          <v-card-actions>
            <v-col class="text-center">
              <v-btn
                @click="cambiarEstadoEvento"
                class="text-center mr-2"
                color="success"
              >
                <v-icon>mdi-floppy</v-icon> ACTUALIZAR
              </v-btn>
              <v-divider vertical class="mx-4"></v-divider>
              <v-btn @click="dialogEstadoEvento = false" class="text-center">
                <v-icon>mdi-cancel</v-icon> CERRAR
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      width="750px"
      v-model="dialogMensajes"
      :persistent="true"
      no-click-animation
    >
      <v-card>
        <v-toolbar height="40" color="green" flat dark>
          <v-toolbar-title
            ><v-icon>mdi-whatsapp</v-icon> mensaje</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogMensajes = !dialogMensajes" small
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                label="Tipo de mensaje"
                v-model="datosMensaje.cmdp_id"
                :items="tiposMensaje"
                item-text="cmdp_nombre"
                item-value="cmdp_id"
                return-object
                @change="cargarMensaje"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                label="Número de teléfono"
                v-model="datosMensaje.telefono"
                :items="telefonos"
                item-text="num_telefono"
                item-value="num_telefono"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Número de teléfono"
                v-model="datosMensaje.telefono"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="datosMensaje.mensaje"
                label="Mensaje"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="enviarMensaje" small dark color="green"
            ><v-icon>mdi-send</v-icon>Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "EventoForm",
  components: {},
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 20,
    itemsPerPageOp: 20,
    itemsPerPageEv: 20,
    itemsPerPageCom: 20,
    itemsPerPageServ: 20,
    pageCount: 0,
    overlay: false,
    required: [(v) => !!v || "El campo es requerido"],

    datosEvento: {
      work_effort_id_id: "",
      current_status_id: "",
      estimated_start_date: "",
      estimated_completion_date: "",
      fecha: "",
      hora: "",
      estado_actual: "",
      created_date: "",
      created_by_user_login: "",
      description: "",
      party_id: "",
      first_name: "",
      last_name: "",
    },

    headersComentarios: [
      { text: "Comentario", align: "start", value: "comment" },
      { text: "Creado por", align: "start", value: "created_by_user_login" },
      { text: "Fecha", align: "start", value: "created_stamp" },
    ],
    comentariosEvento: [],

    headersEstados: [
      { text: "Estado", align: "start", value: "estado" },
      { text: "Fecha", align: "start", value: "status_datetime" },
      { text: "Comentarios", align: "start", value: "reason" },
      { text: "Motivo", align: "start", value: "motivo_id" },
      { text: "Ingresado por", align: "start", value: "set_by_user_login" },
    ],
    estadosEvento: [],

    headersSubservicios: [
      { text: "Servicio", align: "start", value: "internal_name" },
      { text: "Duracion", align: "start", value: "duracion" },
      { text: "Codigo", align: "start", value: "product_id" },
    ],

    subservicios: [],

    servicios: [],
    tiposEvento: [],
    propositosEvento: [],

    headersParticipante: [
      { text: "Codigo", align: "start", value: "party_id" },
      { text: "Nombres", align: "start", value: "first_name" },
      { text: "Apellidos", align: "start", value: "last_name" },
      { text: "Rol", align: "start", value: "tipo_rol" },
      {
        text: "Creado por",
        align: "start",
        value: "assigned_by_user_login_id",
      },
    ],
    participantesEvento: [],

    headersOportunidad: [
      { text: "Codigo", align: "start", value: "sales_opportunity_id" },
      { text: "Nombres", align: "start", value: "opportunity_name" },
      { text: "Monto", align: "start", value: "estimated_amount" },
      {
        text: "Fecha de cierre estimaa",
        align: "start",
        value: "estimated_close_date",
      },
      {
        text: "Creado por",
        align: "start",
        value: "created_by_user_login",
      },
    ],
    oportunidadesEvento: [],

    dialogEstadoEvento: false,
    estados: [],
    eventosPlanificados: [
      "CAL_TAR_COMPLETADO",
      "CAL_TAR_CANCELADO",
      "CAL_TAR_NO_REALIZADO",
      "CAL_TAR_CONFIRMADO",
      "CAL_TAR_NO_CONFIRMA",
      "CAL_TAR_NOASISTE",
      "CAL_ASIS_NOFA",
    ],
    eventosConfirmados: [
      "CAL_TAR_COMPLETADO",
      "CAL_TAR_NO_REALIZADO",
      "CAL_TAR_CANCELADO",
      "CAL_TAR_PLANIFICADO",
      "CAL_TAR_NOASISTE",
      "CAL_ASIS_NOFA",
    ],
    enventosNoRealizados: [
      "CAL_TAR_PLANIFICADO",
      "CAL_TAR_CANCELADO",
      "CAL_TAR_COMPLETADO",
      "CAL_TAR_NOASISTE",
      "CAL_ASIS_NOFA",
    ],

    enventosNoAsiste: [
      "CAL_TAR_PLANIFICADO",
      "CAL_TAR_CANCELADO",
      "CAL_ASIS_NOFA",
    ],
    eventosAsisteNofa: [
      "CAL_TAR_PLANIFICADO",
      "CAL_TAR_CANCELADO",
      "CAL_TAR_NOASISTE",
    ],
    motivos: [],
    nuevoEstadoId: "",
    nuevoMotivoId: "",
    motivo: "",
    eventoEstado: {
      estimated_start_date: "00:00",
      estimated_completion_date: "00:00",
    },
    dialogMensajes: false,
    tiposMensaje: [],
    datosMensaje: {},
    telefonos: [],
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),

    formatoFechaInicioFin() {
      let fi = "";
      let ff = "";
      if (this.datosEvento.estimated_start_date != "") {
        fi =
          this.datosEvento.estimated_start_date.split(" ")[0] +
          "  " +
          this.datosEvento.estimated_start_date.split(" ")[1].substring(0, 5);
      }

      if (this.datosEvento.estimated_completion_date != "") {
        ff =
          this.datosEvento.estimated_completion_date.split(" ")[0] +
          "  " +
          this.datosEvento.estimated_completion_date
            .split(" ")[1]
            .substring(0, 5);
      }
      return fi + "  " + ff;
    },
    formatoFechaInicio() {
      if (this.datosEvento.estimated_start_date != "") {
        return (
          this.datosEvento.estimated_start_date.split(" ")[0] +
          "  " +
          this.datosEvento.estimated_start_date.split(" ")[1].substring(0, 5)
        );
      }
      return "";
    },
    formatoFechaFin() {
      if (this.datosEvento.estimated_completion_date != "") {
        return (
          this.datosEvento.estimated_completion_date.split(" ")[0] +
          "  " +
          this.datosEvento.estimated_completion_date
            .split(" ")[1]
            .substring(0, 5)
        );
      }
      return "";
    },
    formatFechaCreacion() {
      if (this.datosEvento.created_date != "") {
        let f = this.datosEvento.created_date.split(" ")[0];
        let h = this.datosEvento.created_date.split(" ")[1].substring(0, 5);
        return f + "  " + h;
      }

      return "";
    },
    getDatosContacto() {
      return this.datosEvento.contacto + " :: " + this.datosEvento.contacto_id;
    },
    validarTelefono() {
      if (this.datosLead.telefono == null) {
        return [true];
      }

      if (this.datosLead.telefono.match(/\D/)) {
        return ["Teléfono inválido"];
      }
      let phone = this.datosLead.telefono;
      if (phone != null) {
        if (!(phone.length == 10) && !(phone.length == 9))
          return ["Teléfono inválido"];
      }
    },

    validarEmail() {
      if (this.datosLead.email == "") {
        return [true];
      }
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.datosLead.email
        )
      ) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    duracionEvento() {
      let duracion = 0;
      for (let i = 0; i < this.subservicios.length; i++) {
        duracion += parseInt(this.subservicios[i].duracion);
      }
      return duracion;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    guardarEvento() {
      /*if (!this.$refs.formLead.validate()) {
        return false;
      }

      this.setLoadingTable(true);
      this.setUrl("lead");
      this.requestApi({
        method: "POST",
        data: this.datosLead,
      })
        .then((res) => {
          //console.log(res.data);
          //this.cargarDatos();
          this.dialogoLead = false;
          this.alertNotification({
            param: {
              html: res.data.detail.msg,
            },
          });
          this.cargarDatosLead(this.datosLead.party_id);
        })
        .then(() => {
          this.setLoadingTable(false);
        });*/
    },

    cargarDatosEvento(id) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("evento/" + id);
      this.requestApi({
        method: "GET",
        data: {
          typeFind: "cargarDatosEvento",
          work_effort_id: id,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.datosEvento = res.data.evento;
          this.participantesEvento = res.data.roles;
          this.estadosEvento = res.data.estados;
          this.estados = res.data.statuses;
          this.motivos = res.data.motivos;
          this.oportunidadesEvento = res.data.oportunidades;
          this.comentariosEvento = res.data.comentarios;
          this.subservicios = res.data.sub_servicios;
          this.tiposMensaje = res.data.tipos_mensaje;
          this.telefonos = res.data.telefonos;
          this.datosMensaje.telefono = this.telefonos[0].num_telefono;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    cambiarEstadoEvento() {
      if (!this.$refs.form_estatus_evento.validate()) return false;

      Vue.swal({
        html: `Esta seguro de actualizar el estado del evento?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.overlay = true;

          this.setUrl("evento");

          const { work_effort_id } = this.eventoEstado;

          this.requestApi({
            method: "POST",
            data: {
              tenantId: this.tenantId,
              workEffortId: this.datosEvento.work_effort_id,
              statusId: this.nuevoEstadoId,
              motivo: this.motivo,
              motivoId: this.nuevoMotivoId,
              typeStore: "actualizaEstadoEvento",
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.res.msg,
                },
              });

              /*this.eventoEstado = {
                estimated_start_date: "00:00",
                estimated_completion_date: "00:00",
              };*/
              this.cargarDatosEvento(this.$route.params.workEffortId);
              this.dialogEstadoEvento = false;
              this.motivo = null;
              this.nuevoEstadoId = null;
              this.nuevoMotivoId = null;
              this.overlay = false;
            })
            .catch((err) => {
              this.overlay = false;
              console.log(err);
            });
        }
      });
    },
    getListaEstadosValidos() {
      if (this.datosEvento.current_status_id == "CAL_TAR_PLANIFICADO") {
        return this.eventosPlanificados;
      } else if (this.datosEvento.current_status_id == "CAL_TAR_CONFIRMADO") {
        return this.eventosConfirmados;
      } else if (this.datosEvento.current_status_id == "CAL_TAR_NOASISTE") {
        return this.enventosNoAsiste;
      } else if (this.datosEvento.current_status_id == "CAL_ASIS_NOFA") {
        return this.eventosAsisteNofa;
      } else {
        return this.enventosNoRealizados;
      }
    },
    getListaMotivosEstado(estadoId) {
      let me = [];
      for (let i = 0; i < this.motivos.length; i++) {
        if (this.motivos[i].work_effort_status_id == estadoId) {
          me.push(this.motivos[i]);
        }
      }
      return me;
    },
    abrirDialogEnviarMensaje() {
      this.dialogMensajes = true;
    },
    abrirDialogCambioEstado() {
      this.dialogEstadoEvento = true;
    },

    cargarMensaje() {
      this.datosMensaje.device_id = this.datosMensaje.cmdp_id.comm_dev_id;
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("message-template");
      this.requestApi({
        method: "POST",
        data: {
          accion: "generarMensaje",
          cmdp_id: this.datosMensaje.cmdp_id.cmdp_id,
          work_effort_id: this.datosEvento.work_effort_id,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.datosMensaje.mensaje = res.data.detail.mensaje;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
        });
    },

    enviarMensaje() {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("wa-contactos");
      this.requestApi({
        method: "POST",
        data: {
          accion: "enviarMensaje",
          device_id: this.datosMensaje.device_id,
          mensaje: this.datosMensaje.mensaje,
          destino: this.datosMensaje.telefono,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.overlay = false;
          this.abrirDialogEnviarMensaje = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
        });
    },
  },
  mounted() {
    //this.cargarDatos();
    this.cargarDatosEvento(this.$route.params.workEffortId);
    this.setTitleToolbar("EVENTO");
  },
};
</script>
